@import url('https://fonts.googleapis.com/css?family=Anton|Francois+One|Patua+One');

.App {
  text-align: center;

}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 30vmin;
  pointer-events: none;
}

.App-header {
  /*background-color: #282c34;*/
  /*min-height: 100vh;*/

  min-height: 100vh;
  /* If you need to support browser without CSS var support (<= IE11) */
  min-height: calc(100vh - var(--vh-offset, 0px));
  /* enable vh fix */

  width: 100%;
  min-width: 100%;
  max-width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  /*justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: white;
  overflow-x: none;
  overflow-y: none;
  
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
