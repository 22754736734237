@import url(https://fonts.googleapis.com/css?family=Anton|Francois+One|Patua+One);
body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;

}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 30vmin;
  pointer-events: none;
}

.App-header {
  /*background-color: #282c34;*/
  /*min-height: 100vh;*/

  min-height: 100vh;
  /* If you need to support browser without CSS var support (<= IE11) */
  min-height: calc(100vh - 0px);
  min-height: calc(100vh - var(--vh-offset, 0px));
  /* enable vh fix */

  width: 100%;
  min-width: 100%;
  max-width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  /*justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: white;
  overflow-x: none;
  overflow-y: none;
  
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

